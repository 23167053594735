<template>
  <div class="content">
    <div>
      <div class="row">
        <div class="col">
          <input type="file" class="mb-2 form-control" :class="{ 'is-invalid': errors.reviews }" @change="onFileChange" />
          <div v-for="(error, k) in errors.reviews" :key="'errtem'+k" class="invalid-feedback" v-html="errors.template[k]"></div>
        </div>
        <div class="col">
          <button class="vave-btn btn-blue" @click="uploadReviews()">Upload</button>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'reviewsDocumentation' }" class="mb-4 vave-btn">Documentation</router-link>
        </div>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col">
        <div class="spinner-border spinner-border-sm ms-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col">
        <div class="table-container">
          <table class="table">
            <thead class="head">
              <tr>
                <th class="ps-4">Id</th>
                <th>Content</th>
                <th>Product</th>
                <th>Stars</th>
                <th>Quality</th>
                <th>Value</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="review in reviews.data" :key="review.id">
                <td class="ps-4">
                  <span :title="review.review_id">{{review.id}}</span>
                </td>
                <td>{{review.content}}</td>
                <td>({{review.product_sku}}) {{review.product_name}}</td>
                <td>
                  <rating :stars="review.stars" />
                </td>
                <td>
                  <rating :stars="review.quality" />
                </td>
                <td>
                  <rating :stars="review.value_for_money" />
                </td>
                <td>{{formatValue(review.date,'date')}}</td>
              </tr>
            </tbody>
          </table>
          <pagination class="paginator" :limit="4" align="center" :data="reviews" @pagination-change-page="getReviews"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";
import Rating from "./widgets/helpers/Rating";
import pagination from "laravel-vue-pagination";
import { formatValue } from "./../formatters";

export default {
  data() {
    return {
      reviews: {
        file: null,
      },
      loading: true,
      errors: {},
      baseUrl: process.env.VUE_APP_VAVEURL,
    };
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    formatValue,
    getReviews(page = 1) {
      this.loading = true;
      this.reviews = {};
      axios.get("/api/admin/product-reviews?per_page=20&page=" + page).then(
        (r) => {
          this.loading = false;
          if (r.status == 200) {
            this.reviews = r.data;
          }
        },
        (e) => {
          this.loading = false;
          console.log(e);
        }
      );
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.reviews.file = files[0];
      console.log("file saved in variable");
    },
    uploadReviews() {
      this.loading = true;
      this.errors = {};
      let formData = new FormData();
      formData.append("reviews", this.reviews.file);
      axios
        .post("/api/admin/product-reviews", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (r) => {
            console.log(r);
            if (r.status == 201) {
              this.reviews = {
                file: null,
              };
              this.$toast.success("Reviews uploaded correctly");
              this.getReviews();
            }
          },
          (e) => {
            this.getReviews();
            this.$toast.error("Error uploading the reviews");
            if (e.response && e.response.data && e.response.data.errors) {
              console.log("setting errors");
              this.errors = e.response.data.errors;
            }
          }
        );
    },
  },
  components: { Rating, pagination },
};
</script>

<style lang="scss">
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.preview-iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  padding-top: 4em;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>