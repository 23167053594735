<template>
  <div class="reviews">
    <img :src="rating" />
  </div>
</template>

<script>
export default {
  props: ["stars"],
  computed: {
    rating() {
      return this.images["stars" + Math.floor(this.stars * 2)];
    },
  },
  data() {
    return {
      images: {
        stars0: require("./../../../assets/stars/stars-0.svg"),
        stars1: require("./../../../assets/stars/stars-0.svg"),
        stars2: require("./../../../assets/stars/stars-1.svg"),
        stars3: require("./../../../assets/stars/stars-1.5.svg"),
        stars4: require("./../../../assets/stars/stars-2.svg"),
        stars5: require("./../../../assets/stars/stars-2.5.svg"),
        stars6: require("./../../../assets/stars/stars-3.svg"),
        stars7: require("./../../../assets/stars/stars-3.5.svg"),
        stars8: require("./../../../assets/stars/stars-4.svg"),
        stars9: require("./../../../assets/stars/stars-4.5.svg"),
        stars10: require("./../../../assets/stars/stars-5.svg"),
      },
    };
  },
};
</script>

<style lang="scss">
.reviews img {
  width: 120px;
}
</style>
